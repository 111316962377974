import { createDefaultOptions, FieldTypeId } from '../fields';
import axios from 'axios';
import { GatherField, GatherFieldOptions } from '../gather';
import { useToastStore } from '../store/toasts';

export default class Field implements Partial<GatherField> {
  id: number = 0;
  template_section_id = 0;
  field_type_id: FieldTypeId = 1;
  system_reference: null | string = null;
  label: string = '';
  is_required: boolean = false;
  is_permanent = false;
  options: GatherFieldOptions = createDefaultOptions();
  order: number;
  c_template_field_id: null | number = null;
  c_input_value = null;
  created_at: string | null = null;
  updated_at: string | null = null;
  // Status checks
  isUpdating = false;
  isDeleting = false;

  static wrap(field) {
    if (field instanceof Field) {
      return field;
    }

    return new Field(field);
  }

  constructor(
    data: Partial<GatherField> & {
      order: number;
      isUpdating?: boolean;
      isDeleting?: boolean;
      c_template_field_id?: number;
      c_input_value?: any;
    }
  ) {
    this.id = data.id || this.id;
    this.template_section_id =
      data.template_section_id || this.template_section_id;
    this.field_type_id = data.field_type_id || this.field_type_id;
    this.system_reference = data.system_reference || null;
    this.label = data.label || this.label;
    this.is_required = data.is_required || this.is_required;
    this.options = {
      ...createDefaultOptions(),
      ...data.options,
    };
    this.order = data.order;
    this.c_template_field_id =
      data.c_template_field_id || this.c_template_field_id;
    this.c_input_value = data.c_input_value || this.c_input_value;
    this.created_at = data.created_at || this.created_at;
    this.updated_at = data.updated_at || this.updated_at;
    this.isUpdating = data.isUpdating || false;
    this.isDeleting = data.isDeleting || false;
  }

  async save() {
    if (this.id) {
      try {
        this.isUpdating = true;
        const { data } = await axios.put(`/api/template/field/${this.id}`, {
          id: this.id,
          template_section_id: this.template_section_id,
          field_type_id: this.field_type_id,
          system_reference: this.system_reference,
          label: this.label,
          is_required: this.is_required,
          is_permanent: this.is_permanent,
          options: {
            ...createDefaultOptions(),
            ...this.options,
          },
          order: this.order,
          c_template_field_id: this.c_template_field_id,
          c_input_value: this.c_input_value,
        });
        this.created_at = data.data.created_at;
        this.updated_at = data.data.updated_at;
      } catch (e) {
        useToastStore().unexpected(e);
        throw e;
      } finally {
        this.isUpdating = false;
      }

      return;
    }

    try {
      const { data } = await axios.post(`/api/template/field`, {
        template_section_id: this.template_section_id,
        field_type_id: this.field_type_id,
        system_reference: this.system_reference,
        label: this.label,
        is_required: this.is_required,
        is_permanent: this.is_permanent,
        options: {
          ...createDefaultOptions(),
          ...this.options,
        },
        order: this.order,
        c_template_field_id: this.c_template_field_id,
        c_input_value: this.c_input_value,
      });

      this.id = data.data.id;
      this.created_at = data.data.created_at;
      this.updated_at = data.data.updated_at;
    } catch (e) {
      useToastStore().unexpected(e);
      throw e;
    }
  }

  async delete() {
    try {
      this.isDeleting = true;
      const { data } = await axios.delete(`/api/template/field/${this.id}`);
      return data;
    } catch (e) {
      throw e;
    } finally {
      this.isDeleting = false;
    }
  }
}
